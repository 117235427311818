import React from "react";
import { Component, createElement } from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import NavigationIcon from '@material-ui/icons/Navigation';

import createSocket from "sockette-component";
import jsmpeg from 'jsmpeg';
import {getAccess, getNow} from "../../../services/camera";
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


import JsmpegPlayer from '../../../components/JsmpegPlayer/JsmpegPlayer';

import Slide from '@material-ui/core/Slide';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import ReactMomentCountDown from '../../../components/CountDown';
import moment from 'moment-timezone';

import queryString from 'query-string';


// @material-ui/icons

// core components



import videoStyle from "../../../assets/jss/material-kit-react/views/landingPageSections/videoStyle.jsx";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const Sockette = createSocket({
  Component,
  createElement
});

const videoOptions = {
  poster: 'https://cycjimmy.github.io/staticFiles/images/screenshot/big_buck_bunny_640x360.jpg'
};

const videoOverlayOptions = {};

class VideoSection extends React.Component {
  constructor(props, context) {
    super(props, context);
    // set the initial component state
    const parsed = queryString.parse(document.location.search);
  

    if (parsed && parsed.accessCode) {
      
      this.state = {
        authenticated : false,
        socket: null,
        code: parsed.accessCode,
        startVisit: (moment().tz("Europe/Bucharest").isAfter(moment().hour(14)) ?  moment().tz("Europe/Bucharest").add(1,'day').hour(14).minute(0).second(0) : moment().tz("Europe/Bucharest").hour(14).minute(0).second(0)),
        endVisit: (moment().tz("Europe/Bucharest").isAfter(moment().hour(15)) ? moment().tz("Europe/Bucharest").add(1,'day').hour(15).minute(0).second(0) : moment().tz("Europe/Bucharest").hour(15).minute(0).second(0)),
        currentDate: moment().tz("Europe/Bucharest"),
        endCountdown: false,
        isMaintenance: false,
        loading:false,
        messageOpen: false,
        approved: false,
        wsURL:'',
        emergency:false
      };
      
      if (moment().tz("Europe/Bucharest").isAfter(this.state.startVisit) &&
      moment().tz("Europe/Bucharest").isBefore(this.state.endVisit)) {
        this.authenticate();
      }
    } else {
      this.state = {
        authenticated : false,
        socket: null,
        code: '',
        startVisit:  (moment().tz("Europe/Bucharest").isAfter(moment().tz("Europe/Bucharest").hour(14)) ?  moment().tz("Europe/Bucharest").add(1,'day').hour(14).minute(0).second(0) : moment().tz("Europe/Bucharest").hour(14).minute(0).second(0)),
        endVisit: (moment().tz("Europe/Bucharest").isAfter(moment().tz("Europe/Bucharest").hour(15)) ?  moment().tz("Europe/Bucharest").add(1,'day').hour(15).minute(0).second(0) : moment().tz("Europe/Bucharest").hour(15).minute(0).second(0)),
        currentDate: moment().tz("Europe/Bucharest"),
        endCountdown: false,
        isMaintenance: false,
        loading:false,
        messageOpen: false,
        approved: false,
        wsURL:'',
        emergency:false
      };
    }


    this.waitOnTick = this.waitOnTick.bind(this)
    this.waitOnCountdownEnd = this.waitOnCountdownEnd.bind(this)
    this.waitOnTickEnd = this.waitOnTickEnd.bind(this)
    this.waitOnCountdownEndVisit = this.waitOnCountdownEndVisit.bind(this)

  }

  onOpen = ev => {

    this.setState({ws:ev.target});
    this.sendRequest('connect', {
      'address': this.state.visitor.camera.ip,
      'user'   : this.state.visitor.camera.user,
      'pass'   : this.state.visitor.camera.password
    });
  };
 

  onMessage = ev => {
    
    var response = JSON.parse(ev.data);
    if (response.id === "connect") { 
      if (response.error) {
        this.setState(
          { loading:false,
            authenticated:false,
            messageOpen:true,
            approved:false,
            message:'Camera nu este disponibila in acest moment!'
          });  
      } else {
        var port = this.state.visitor.camera.port;
        var path = this.state.visitor.camera.path;
        this.setState({wsURL: 'wss://portal.e-neonat.ro/'+path});
        setTimeout(()=>{  
          var canvas = document.getElementsByClassName('video-wrapper');
          canvas[0].style.width = '100%';
          canvas[0].height = canvas[0].width * .75;	
          this.setState({loading:false});
        }, 10000)
       
       
        
      }
    } else if (response.id === "emergency") {
          this.setState({emergency:true, wsURL:''});
    }
  };

  goHome = () => {
    this.sendRequest('ptzHome', {
      'address': this.state.visitor.camera.ip,
      'timeout': 30
    });
  }

  

  moveZoom = (left, top, zoom) => {
    this.setState({
      x: this.state.x + left,
      y: this.state.y + top,
      z: this.state.z + zoom
    })
    var pos = {x: 0, y: 0, z: 0};
    pos.x = left
    pos.y = top;
    pos.z = zoom;
    this.sendRequest('ptzMove', {
      'address': this.state.visitor.camera.ip,
      'speed'  : pos,
      'timeout': 3
    });
   
  }

  moveAbsZoom = (left, top, zoom, posx, posy,posz) => {
 
    var speed = {x: 0, y: 0, z: 0};
    speed.x = left
    speed.y = top;
    speed.z = zoom;

    var pos = {x: 0, y: 0, z: 0};
    pos.x = posx
    pos.y = posy;
    pos.z = posz;
    this.sendRequest('ptzMove', {
      'address': this.state.visitor.camera.ip,
      'speed'  : speed,
      'position': pos
    });
   
    
  }

  onReconnect = ev => {
    //console.log("> Reconnecting...", ev);
  };

  sendRequest = (method, params) => {
    this.state.ws.send(JSON.stringify({
      'method': method,
      'params': params
    }));
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
    
  };

  authenticate = async () => {
    
     const response = await getAccess(
        this.state.code
     );
     
     if (response && response.access) {
        this.setState({
          authenticated:true,
          loading: true,
          visitor: response.visitor,
        });  
     } else if (response && !response.access) {
      this.setState({ 
        message: response.message,
        messageOpen: true,
        code:'',
        approved:false });
     }
  }

  handleClose = () => {
    this.setState({ messageOpen: false });
  };
  handleApprove = () => {
    this.setState({approved: !this.state.approved});
  }

  waitOnTick =async  (countdown) => {
    const response =  await getNow();
    const format1 = "YYYY-MM-DD HH:mm:ss"
    this.setState({currentDate: response ? moment(response.date, format1): moment()})
    this.setState({ endCountdown: countdown <= 0 })
  }
  waitOnCountdownEnd () {
    console.log('visit started');
  }

  waitOnTickEnd (countdown) {
    
  }
  waitOnCountdownEndVisit () {
    console.log('visit end');
    window.location.reload();
  }
  componentDidMount = async () => {
    const response =  await getNow();
    const format1 = "YYYY-MM-DD HH:mm:ss"
    this.setState({currentDate: response ? moment(response.date, format1): moment()})
  }

  render() {
    const { classes } = this.props;
    
    return (
      <div>
      {this.state.endCountdown && <ReactMomentCountDown  fromDate={this.state.currentDate} toDate={this.state.endVisit}
      sourceFormatMask='YYYY-MM-DD HH:mm:ss'
      onTick={this.waitOnTickEnd}
      onCountdownEnd={this.waitOnCountdownEndVisit} />}
      {this.state.isMaintenance &&<div className={classes.section}>
      <div className={classes.authenticate}>
      <Typography variant="title" style={{color:"#fff",fontSize:'36px'}}>Portalul este in acest moment in mentenanta! <br/> Vom reveni curand! <br/><br/> <br/>  Multumim pentru intelegere!</Typography>
      </div>
      </div>}
     {!this.state.isMaintenance && <div className={classes.section}>

        {!this.state.authenticated ? 
          <div className={classes.authenticate} >
            <Typography variant="title" style={{color:"#fff"}}>Autentificare Vizită Online</Typography>
            <div className={classes.formContainer}> 
                <br/>
                <br/>
                <br/>
                {!this.state.endCountdown &&  <div style={{fontSize:'48px'}}>
                <Typography variant="title" style={{color:"#fff"}}>Accesul la vizita online se face numai între orele 14:00-15:00.<br/> Timpul rămas până la vizită: </Typography> 
                <br/>
                <br/>
                <ReactMomentCountDown fromDate={this.state.currentDate} toDate={this.state.startVisit}
                  sourceFormatMask='YYYY-MM-DD HH:mm:ss'
                  onTick={this.waitOnTick}
                  onCountdownEnd={this.waitOnCountdownEnd} />
                 
                </div>}

               {this.state.endCountdown && !this.state.emergency && <form className={classes.container} noValidate autoComplete="off">
                <Typography variant="title" style={{color:"#fff"}}>Introduceți codul de access furnizat <br/> special pentru dumneavoastră </Typography>
                <br/>
                <br/>
              
                <TextField
                  id="code"
                  className={classes.textField}
                  value={this.state.name}
                  onChange={this.handleChange('code')}
                  margin="normal"
                  type="password"
                  InputProps={{
                    className: classes.input,
                    disableUnderline: true,
                  }}
                  InputLabelProps= {{
                    className: classes.label,
                  }}
                />
              <br/>
              <FormGroup row style={{alignItems:"center", textAlign:"center", justifyContent: "center", color:"#fff"}}>
              <FormControlLabel
                classes={{
                  label: classes.label,
                }}
                control={
                <Checkbox
                  checked={this.state.approved}
                  onChange={() => {this.handleApprove()}}
              />
          }
          label="Sunt de acord cu regulamentul de vizitare prezentat"
        />
        </FormGroup>
                <Button variant="extendedFab" disabled={!this.state.approved} aria-label="Delete" className={classes.button} onClick={this.authenticate}>
                  <NavigationIcon className={classes.extendedIcon} />
                  Accesează video
                </Button>
                </form>}
                <Dialog
                open={this.state.messageOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogTitle id="alert-dialog-slide-title">
                  {"Autentificare vizita online"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description">
                    {this.state.message}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleClose} color="primary">
                    Ok
                  </Button>
                </DialogActions>
              </Dialog>
              
            </div>
          </div> : 
          <div className={classes.videoContainer}>

          {this.state.emergency &&  <div style={{fontSize:'48px'}}>
          <Typography variant="title" style={{color:"#fff"}}>Accesul la vizita online a fost intrerupt.<br/></Typography> 
          <br/>
          <br/>
          
          </div>}
          {!this.state.emergency && <div>
             {!this.state.loading && <Typography variant="title" className={classes.cameraTitle}>{this.state.visitor.camera.name}</Typography>} 
          {this.state.loading && <Typography variant="title" style={{color:"#fff"}}>Vă rugăm așteptați până se face conexiunea cu camera video!</Typography>}
          
           {/* <canvas id="video-canvas" styles={{flex: 1, width: '100%', backgroundColor:'#ff0'}}></canvas> */}
          
            {this.state.loading && <br/>}
            {this.state.loading && <CircularProgress  size={50} thickness={7} color="secondary" />}
            <br/>
            {this.state.wsURL && this.state.endCountdown && <div style={{display: this.state.loading ? 'none':'block'}}><JsmpegPlayer
              wrapperClassName="video-wrapper"
              id="video-canvas"
              videoUrl={this.state.wsURL}
              options={videoOptions}
              overlayOptions={videoOverlayOptions}
            /></div>}
         {/*  <Button variant="extendedFab" aria-label="Delete" className={classes.button} onClick={this.goHome}>
                  Home
        </Button>*/}
            <Sockette
              url={"wss://portal.e-neonat.ro/ws"}
              getSocket={socket => {
                this.setState(socket);
              }}
              maxAttempts={10}
              onopen={this.onOpen}
              onmessage={this.onMessage}
              onreconnect={this.onReconnect}
            />
            </div>}
          </div>
        }
      </div>}
      </div>
    );
  }
}

export default withStyles(videoStyle)(VideoSection);
