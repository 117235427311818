import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import ListItem from '@material-ui/icons/HourglassEmpty';

// @material-ui/icons

import MenuIcon from '@material-ui/icons/Menu';

// core components
import headerStyle from "../../assets/jss/material-kit-react/components/headerStyle.jsx";

import logo_inima_copiilor_white from "../../assets/img/logos/Group 2.png";

import logo_vodafone_white from "../../assets/img/logos/vodafone-logo.png";
import logo_vodafone_foundation from "../../assets/img/logos/vodafone-foundation.png";


class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      auth: true,
      anchorEl: null,
    };
  }
  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };
  changeIndex = (index) => {
    this.props.changeTabIndex(index);
  };

  emergency = () => {
    this.props.emergency();
  };

  logout = () => {
    this.props.logout();
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };


  render() {
    const {
      classes,
      color,
      fixed,
      absolute,
      centered,
      minimal
    } = this.props;
    const appBarClasses = classNames({
      [classes.appBar]: true,
      [classes[color]]: color,
      [classes.absolute]: absolute,
      [classes.fixed]: fixed
    });
    const toolbarClasses = classNames({
      [classes.container]:true,
      [classes.centered]: centered
    });
    const brandComponent = (
      <Button className={classes.title} styles={{marginRight:"160px"}}>
       <img className={classes.logo}  id="logo_app" height="60" src={logo_inima_copiilor_white} alt="inima copiilor"/>
      </Button>
    );
    const sponsorComponent = (
      <Button className={classes.title}>
       <img className={classes.logo} id="logo_app" height="50" src={logo_vodafone_foundation} alt="vodafone"/>
      </Button>
    );
    const { auth, anchorEl } = this.state;
    const open = Boolean(anchorEl);
    return (
      <div>
      { minimal && <AppBar position="static">
      <Toolbar variant="dense">
        <Typography variant="title" color="inherit" className={classes.flex}>
        PORTAL PENTRU PĂRINȚI
        </Typography>
        <Button color="inherit" onClick={()=>{this.logout()}}>iesire</Button>
        <Button variant="contained" color="secondary" onClick={()=>{this.emergency()}}>URGENTA TOATE CAMERELE</Button>
      </Toolbar>
    </AppBar>

      }
      {!minimal && <Hidden smDown implementation="css"> 
      <AppBar className={appBarClasses}>
        
          <Toolbar className={toolbarClasses}>        
         {brandComponent}
        
          <Typography style={{color:'#fff',fontWeight:900,   shadowColor: 'rgb(255, 0, 255)',
          shadowOffset: {
            height: 2,
          },
          shadowOpacity: 1.0,
          shadowRadius: 4}} variant="headline">PORTAL PENTRU PĂRINȚI</Typography>
          {sponsorComponent}
       
          </Toolbar> 
        
      </AppBar>
      </Hidden>}
      {!minimal && <Hidden mdUp implementation="css">
      <AppBar position="static">
          <Toolbar variant="dense">
            <Typography variant="title" color="inherit" className={classes.flex}>
            PORTAL PENTRU PĂRINȚI
            </Typography>
            {auth && (
              <div>
                <IconButton
                  aria-owns={open ? 'menu-appbar' : null}
                  aria-haspopup="true"
                  onClick={this.handleMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={open}
                  onClose={this.handleClose}
                >
                  <MenuItem onClick={()=>{this.changeIndex(0)}}>Vizitează online</MenuItem>
                {/*  <MenuItem disabled onClick={()=>{this.changeIndex(2)}}><ListItem/>Vizualizare analize</MenuItem> */}
                  <MenuItem onClick={()=>{this.changeIndex(1)}}>Informații generale</MenuItem>
                  <MenuItem onClick={()=>{this.changeIndex(2)}}>Regulament vizitare</MenuItem>
                </Menu>
              </div>
            )}
          </Toolbar>
        </AppBar>
      </Hidden>}
      </div>
    );
  }
}

Header.defaultProp = {
  color: "white"
};

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "transparent",
    "white",
    "rose",
    "dark"
  ]),
  changeTabIndex: PropTypes.func,
  brand: PropTypes.string,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,

};

export default withStyles(headerStyle)(Header);
