import { container, title } from "../../material-kit-react.jsx";

const homePageStyle = {
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    marginTop: "24px",
    backgroundColor:"#F2F2F2",
    padding:0,
    ...container
  },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    minHeight: "32px",
    marginTop: "20px",
    fontWeight:"600",
    color: "#FFFFFF",
    textDecoration: "none"
  },
  loginButton: {
    zIndex:999,
    marginTop:'3px',
    marginRight:'3px',
    height:'24px',
    position:'relative',
    marginLeft:'auto',
  },
  labelIcon: {
    display:'contents',
  },
  tabContainer: {
    minHeight:"250px",
    marginBottom:"20px",
    padding:0,
    margin:0,
    flexDirection:"row",
    alignItems:"stretch",
    ":parent" : {
      padding:"0!important",
      backgroundColor:"#ff0000",
      padding:0
    }
  },
  logo: {
    maxWidth: "50px",
    height: "50px",
  },
  root: {
    flexGrow: 1,
  },
  flex: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px auto 0"
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3"
  },
  mainRaised: {
    margin: "-30px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  }
};

export default homePageStyle;
