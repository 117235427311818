import React from "react";

import Typography from "@material-ui/core/Typography";

import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';

// nodejs library that concatenates classes

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MessageIcon from '@material-ui/icons/Message';
import AutoIcon from '@material-ui/icons/Autorenew';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import Slide from "@material-ui/core/Slide";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Moment from 'react-moment';
import LockIcon from '@material-ui/icons/LockOpen';
import adminPageStyle from "../../assets/jss/material-kit-react/views/adminPage";
import {getCameras, addVisitor, changeCameraState,changeVisitorState} from "../../services/camera";




import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';


function Transition(props) {
  return <Slide direction="down" {...props} />;
}
function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};




class AdminPage extends React.Component {
  state = {
    value: 0,
    cameras:[],
    open: false,
    accessCode: '',
    currentCamera: null
  };
  componentDidMount = () => {
    this._loadCameras()
  }

  _loadCameras = async () => {
    const response = await getCameras();
    this.setState({cameras:response.cameras});
  }


  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleDelete = async (visitor) => {
    let visitorState = { active: false }
    await changeVisitorState(visitorState, visitor._id);
    await this._loadCameras()
  }


  handleAvailabilityChange = async (camera) => {
    let cameraState = { enabled: !camera.enabled }
    await changeCameraState(cameraState, camera._id);
    await this._loadCameras()
  };

  handleClickOpen = (currentCamera) => {
    this.setState({ open: true, currentCamera: currentCamera });
  };
  handleAuto = async (currentCamera) => {
    const visitor= {
      camera: currentCamera._id
    };
    await addVisitor(visitor)
    await this._loadCameras()
  };

  handleClose = async (save) => {
    if (save) {
      const visitor= {
        accessCode: this.state.accessCode,
        camera: this.state.currentCamera._id
      };
      await addVisitor(visitor)
      await this._loadCameras()
      
    }
    this.setState({ open: false,currentCamera:null, accessCode:'' });
  };

  forceClose = async () => {
    let cameraState = { emergency: true }
    await changeCameraState(cameraState, 0);
  }


 // Modern syntax >= React 16.2.0


  render() {
    const Camera = ({cameras}) => (
    
      <Grid container spacing={8}>
        {cameras.map(camera => (
          <Grid item xs={12} sm={6} md={3} lg={2} xl={2}  key={camera._id} style={{flexGrow:1, alignItems:'stretch', height:'100%'}}>
            <Card className={classes.card} style={{backgroundColor:camera.enabled ? "#ffffff" : "#cccccc"}}>
              <CardContent>
                <Grid container direction="row" style={{flexGrow:1, alignItems:'center', justifyContent:"space-between", flex:'1'}}>
                  <Typography variant="subheading" style={{color:"#000"}}>
                    {camera.name}
                  </Typography>
                  <div>
                    <Tooltip title="Adauga cod manual" placement="top-end">
                      <IconButton className={classes.button} aria-label="add manual" onClick={() => {this.handleClickOpen(camera)}}>
                        <MessageIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Adauga cod automat" placement="top-end">
                      <IconButton className={classes.button} aria-label="Delete" onClick={()=>{this.handleAuto(camera)}}>
                        <AutoIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                </Grid>
                <div className={classes.chipContent}>
                  {camera.visitors ? camera.visitors.map(visitor => (
                    <Chip
                      key={visitor._id}
                      style={{fontFamily:"Courier New", fontSize:15, fontWeight:700}}
                      label={visitor.accessCode}
                      onDelete={() => {this.handleDelete(visitor)}}
                      className={classes.chip}
                      color={visitor.expired?"secondary":"primary"}
                      variant="outlined"/>
                  )): <div></div> }
                </div>
              </CardContent>
              <CardActions>
                <Button size="small" variant="contained" color={camera.enabled?'secondary':'primary'}  onClick={() => {this.handleAvailabilityChange(camera)}}> {camera.enabled?'URGENTA':'Activeaza'}</Button>
                <Typography variant="caption" style={{color:"#333"}}>
                  {camera.lastAccessed ?  <Moment fromNow ago date={camera.lastAccessed}/> :  'Niciodata'}
                </Typography> 
              </CardActions>
            </Card>
          </Grid>))}
      </Grid>
    ); 
    const { classes,  ...rest } = this.props;
    return (
      <div>
        <Header
          color="transparent"
          fixed
          minimal
          logout= {() => {this.props.history.push("/logout")}}
          emergency = {() => {this.forceClose()}}
          {...rest}
        />
      
        <div className={classes.container} style={{paddingTop: "20px"}}>
          <div style={{minHeight:"300px"}}> 
            <Camera cameras={this.state.cameras}/>
          </div>
          <Dialog
            classes={{
              root: classes.center,
              paper: classes.modal
            }}
            TransitionComponent={Transition}
            open={this.state.open}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Adauga Cod</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Adauga cod de access la camera
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            value={this.state.accessCode}
            onChange={(event)=>{this.setState({accessCode:event.target.value})}}
            label="Cod de access"
            type="text"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {this.handleClose(false)}} color="accent">
            Renunta
          </Button>
          <Button onClick={() => {this.handleClose(true)}}  color="primary">
            Salveaza
          </Button>
        </DialogActions>
      </Dialog>
      <Footer />
      </div>    
    </div>
    );
  }
}

export default withStyles(adminPageStyle)(AdminPage);
