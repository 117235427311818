/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
import { List, ListItem, Button, withStyles } from "@material-ui/core";

// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
import Typography from '@material-ui/core/Typography';
import Hidden from "@material-ui/core/Hidden";

import footerStyle from "../../assets/jss/material-kit-react/components/footerStyle.jsx";
import logo_address from "../../assets/img/logos/address.png";
import logo_marie_curie from "../../assets/img/logos/marie-curie-logo.png";
import logo_neonat from "../../assets/img/logos/neonat-logo.png";
import logo_carol_davila from "../../assets/img/logos/logo-text-carol-davila.png";


function Footer({ ...props }) {
  const { classes, whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const location0Component = (
    
    <img className={classes.logo} id="logo_app" height="150"  src={logo_address} alt="neonat"/>
   
 );
  const location1Component = (
    
     <img className={classes.logo} id="logo_app" height="150" src={logo_neonat} alt="neonat"/>
    
  );
  const location2Component = (
    
     <img className={classes.logo} id="logo_app" height="150" src={logo_marie_curie} alt="marie curie"/>
    
  );

  const location3Component = (
    
    <img className={classes.logo} id="logo_app" height="150" src={logo_carol_davila} alt="carol_davila"/>
   
 );
 
  return (
    <div>
    <Hidden smDown implementation="css">
    <footer className={footerClasses}>
      <div className={classes.container}>
      
       {/* <div className={classes.left}>
          <Typography variant="body2" style={{fontSize:"13px"}}>Contact</Typography>
          <Typography variant="body1" style={{fontSize:"12px"}}>Adresa</Typography>
          <Typography variant="body1" style={{fontSize:"12px"}}>Bd. Constantin Brâncoveanu Nr. 20 <br/> Sector 4, București, România</Typography>
          <Typography variant="body1" style={{fontSize:"12px"}}>Tel: +40 (21) 4 603 026 interior 412</Typography>
          <Typography variant="body1" style={{fontSize:"12px"}}>     +40 (372) 298 506</Typography>
          <Typography variant="body1" style={{fontSize:"12px"}}>     +40 (372) 110 640</Typography>
  </div> */}
        {location0Component}
      
      </div>
      <div className={classes.container}>
        {location1Component}
      </div>
      <div className={classes.container}>
        {location2Component}
      </div>
      <div className={classes.container}>
        {location3Component}
      </div>
    </footer>
    </Hidden>
    <div className={classes.center}>
          &copy; {1900 + new Date().getYear()} , made with{" "}
          <Favorite className={classes.icon} /> by{" "}
          <a
          href="https://www.e-neonat.ro/echipa-cat"
          className={aClasses}
          target="_blank"
        >
        Dr. Cătălin Gabriel Cîrstoveanu
        </a>{" & "}
          <a
            href="https://enso-foundation.org"
            className={aClasses}
            target="_blank"
          >
            Bogdan Răduță
          </a>
          .
        </div>
      </div>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  whiteFont: PropTypes.bool
};

export default withStyles(footerStyle)(Footer);
