import React from "react";

import Typography from "@material-ui/core/Typography";

import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Hidden from "@material-ui/core/Hidden";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import LockIcon from '@material-ui/icons/Lock';
import Header from "../../components/Header/Header.jsx";
import Footer from "../../components/Footer/Footer.jsx";
import SwipeableViews from 'react-swipeable-views';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import FavoriteIcon from '@material-ui/icons/HourglassEmpty';
import IconButton from '@material-ui/core/IconButton';

import homePageStyle from "../../assets/jss/material-kit-react/views/homePage.jsx";

import GeneralInfo from "./Sections/GeneralInfoSection.jsx";
import RulesInfo from "./Sections/RulesSection.jsx";
import VideoStream from "./Sections/VideoSection.jsx";
import HowToSection from "./Sections/HowToSection.jsx";


function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500, 
    flexGrow: 1,
  }
});


class HomePage extends React.Component {
  state = {
    value: 0,
  };
  componentDidMount() {
  
  }


  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };


  render() {
    const { classes,  ...rest } = this.props;
    return (
      <div>
        <Header
          color="transparent"
          fixed
          changeTabIndex={this.handleChangeIndex}
          {...rest}
        />
        
        <div className={classes.container}>
       
        <Hidden smDown implementation="css">  
          <Tabs
            value={this.state.value}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="secondary">
            <Tab label="Vizitează Online" />
          {/*<Tab label="Vizualizare analize" icon={<FavoriteIcon /> } classes={{ wrapper: classes.labelIcon }} disabled />*/}
          <Tab label="Cum se folosește" />  
          <Tab label="Informații generale" />
            <Tab label="Regulament Vizitare" />
            <Button variant="extendedFab" aria-label="Login" 
            onClick={() => {this.props.history.push("/admin")}}
            className={classes.loginButton}>
            <LockIcon className={classes.extendedIcon} />
            Login
          </Button>
       
          </Tabs>
        </Hidden>
        
        <SwipeableViews
          index={this.state.value}
          
          onChangeIndex={this.handleChangeIndex}
        >
          <TabContainer style={{padding:0}}>
            <div className={classes.tabContainer}>
           {this.state.value ===0 &&  <VideoStream/>}
            </div>
          </TabContainer>
          {/*<TabContainer>
          </TabContainer>*/}
          <TabContainer>
          <div className={classes.tabContainer}>
          {this.state.value ===1 &&  <HowToSection/>}
          </div>
        </TabContainer>
          <TabContainer>
            <div className={classes.tabContainer}>
            {this.state.value ===2 &&  <GeneralInfo/>}
            </div>
          </TabContainer>
          <TabContainer>
            <div className={classes.tabContainer}>
            {this.state.value ===3 && <RulesInfo/> }
            </div>
          </TabContainer>
        </SwipeableViews>
        <Footer />
        </div>
        
      
      </div>
    );
  }
}

export default withStyles(homePageStyle)(HomePage);
