import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Paper from '@material-ui/core/Paper';

// @material-ui/icons

// core components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";


import aboutStyle from "../../../assets/jss/material-kit-react/views/landingPageSections/aboutStyle.jsx";

class GeneralInfoSection extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Paper className={classes.section}>

        <GridContainer justify="center" className={classes.newContent}>
          <GridItem xs={12} sm={12} md={12}>
            <Typography variant="display1">Secţia de terapie intensivă neonatală - informaţii</Typography>
              <br/>
              <br/>
            <Typography variant="title">Informații pentru părinți </Typography>
            <h5 className={classes.leftAligned}>
            <br/>
            Felicitări pentru noul membru al familiei! Știm că sunteți îngrijorați pentru problemele medicale ale copilului dumneavoastră, dar încercăm să  îi oferim toate condițiile pentru o cât mai buna îngrijire medicală și pentru o recuperare rapidă.
            <br/>
            Acest ghid vă oferă o prezentare generală a Secției de Terapie Intensivă Neonatală de la Spitalul Maria Sklodowska Curie (n.r. TINN sau NICU, în engleză).
            <br/>
            Vă încurajam să citiți cu atenție informațiile oferite. Mai mult, sunteți bineveniți în Secție și încurajați să petreceți cât mai mult timp cu copilul dumneavoastră și să fiți implicați in îngrijirea acestuia.
            <br/>
            Medicii și asistentele  medicale implicați în îngrijirea copilului dumneavoastră vă vor răspunde la orice întrebare. Ne dorim să avem cu dumneavoastră o comunicare directă și deschisă.
            <br/>
            Încercăm să menținem liniștea în Secție pentru a-i permite copilului dumneavoastră să doarmă, să crească și să se însănătoșească. Într-un mediu liniștit copiii sunt mult mai stabili decât într-un mediu zgomotos.         
            </h5>
          </GridItem>
        </GridContainer>
        
        <GridContainer justify="center" className={classes.newContent}>
          <GridItem xs={12} sm={12} md={12}>  
            <Typography variant="title">Echipa</Typography>
            <h5 className={classes.leftAligned}>
            <br/>
            Veți găsi o listă cu toți profesioniștii implicați în îngrijirea copilului dumneavoastră și rolul lor pe parcursul internării. Cu asistenta de serviciu, medicul de gardă  și cu medicul principal implicat în îngrijirea nou-născutului  veți putea comunica de câte ori aveți nevoie.
            </h5>
          </GridItem>
          
        </GridContainer>

        <GridContainer justify="center" className={classes.newContent}>
          <GridItem xs={12} sm={12} md={12}>
          <Typography variant="title">Programul nou-născutului</Typography>
            <h5 className={classes.leftAligned}>
            
            <br/>
              Toți prematurii sau nou-născuții aflați în stare critică sau în post terapie intensivă  au nevoie de îngrijiri la intervale regulate, sau de urgență, în funcție de starea lor, respectiv: îngrijirea plăgilor chirurgicale, proceduri respiratorii sau chiar resuscitare, administrarea medicației, transfuzii, cântărire,  verificarea temperaturii, schimbarea scutecului si spălarea cu soluții antiseptice, nutriția sau proceduri diagnostice. Când copilul dumneavoastră este mutat în Secția post terapie intensiva (la etajul superior), sau când staționează în zona pacienților critici, anumite activități vor fi realizate de dumneavoastră, în calitate de părinți. Asistenta vă îndrumă până ce dobândiți rutina. Comunicați cu asistenta de salon pentru a afla ce presupune rutina nou-născutului.
            </h5>
          </GridItem>
        </GridContainer>

        <GridContainer justify="center" className={classes.newContent}>
        <GridItem xs={12} sm={12} md={12}>
          <Typography variant="title">Folosirea telefoanelor mobile</Typography>
          <h5 className={classes.leftAligned}>
          <br/>
          Telefoanele mobile trebuie <b>închise</b> sau puse pe modul <b>silențios/vibrații.</b> Vă rugam să <b>nu efectuați convorbiri telefonice în interiorul Secției TINN.</b> Apreciem dacă înțelegeți acest lucru. Pentru a păstra o igiena cât mai riguroasă și un ambient cât mai sănătos, vă rugăm <b>să nu folosiți telefoanele mobile în camera copilului dumneavoastră.</b> Apreciem deosebit dacă respectați aceste reguli.
          </h5>
        </GridItem>
      </GridContainer>

      <GridContainer justify="center" className={classes.newContent}>
        <GridItem xs={12} sm={12} md={12}>
          <Typography variant="title">Depozitarea hainelor si bagajelor dumneavoastră</Typography>
          <h5 className={classes.leftAligned}>
          <br/>
          Dacă sunteți vizitatori și nu sunteți cazați în limita celor 8 locuri, pe care Secția le pune la dispoziția mamelor, vă rugăm să vă depozitați lucrurile în spațiile special amenajate la intrarea în Secție, respectiv într-unul dintre cele 18 dulapuri. Pentru siguranța obiectelor, blocaţi cu cifrul dulapul dumneavoastră. La terminarea vizitei vă rugăm să eliberați dulapul și să nu îl țineți blocat mai mult decât este nevoie. Le permiteți astfel și altor părinți să beneficieze de un spațiu de depozitare. Vă rugăm să <b>nu blocați dulapurile pe perioada internării copilului dumneavoastră.</b>
          </h5>
        </GridItem>
      </GridContainer>

      <GridContainer justify="center" className={classes.newContent}>
        <GridItem xs={12} sm={12} md={12}>
          <Typography variant="title">Programul de nutriție</Typography>
          <h5 className={classes.leftAligned}>
          <br/>
          Nutriția pacienților noștri este unul dintre cele mai importante aspecte din secția noastră. Fără o nutriție eficientă vindecarea este tardivă sau uneori deloc, iar riscurile infecțioase și metabolice sunt majore.
          <br/>
          Prematurii sau nou-născuții cu diverse probleme au un program special de alăptare, la intervale fixe, de regulă la fiecare 3 ore. La anumiți pacienți, folosim și alte moduri de nutriție: la 2 ore, sau cu pompe speciale, continuu. Unii nu pot fi hrăniți enteral pe perioade îndelungate datorită unor afecțiuni majore. Întrebați asistenta de salon când trebuie să alăptați sau să colectați lapte matern cu pompa individuală din camera copilului dumneavoastră și asigurați-vă că sunteți disponibilă  în aceste ore.
          <br/>
          Dacă nutriția enterală este temporizată din motive medicale, aceasta este asigurată parenteral, adică vom asigura linii centrale (catetere) și vom administra toate substanțele nutriționale necesare dezvoltării normale a copilului dumneavoastră. Chiar și în această perioadă copilul dumneavoastră va crește. Administrarea acestor perfuzii speciale se face computerizat, eliminând la maximum  riscul erorilor umane. Prepararea hranei se face într-un ambient steril, computerizat, cu ajutorul unui echipament care reduce la maxim erorile și riscul de contaminare.
          </h5>
        </GridItem>
      </GridContainer>

      <GridContainer justify="center" className={classes.newContent}>
        <GridItem xs={12} sm={12} md={12}>
          <Typography variant="title">Programul de liniște</Typography>
          <h5 className={classes.leftAligned}>
          <br/>
          Pentru o cât mai bună dezvoltare neuropsihică între orele 14.00-15.00, dacă permit condițiile medicale, creăm un ambient propice somnului tuturor copiilor  din secție. Insistam ca în aceasta perioadă să intrați în ambianța secției și împreună să creăm condiții pentru ca toți nou-născuții sa doarmă.
          </h5>
        </GridItem>
      </GridContainer>

      <GridContainer justify="center" className={classes.newContent}>
        <GridItem xs={12} sm={12} md={12}>
        <Typography variant="title">Programul de "vizită" de la distanță</Typography>
          <h5 className={classes.leftAligned}>
          <br/>
          În cazul în care nu vă puteți vizita copilul, puteți fi „prezenți” în secție, în timp real și puteți afla informații despre copil din orice loc în care v-ați afla în România.
          <br/>
          Dispunem de un sistem de supraveghere performant în fiecare dintre cele 27 de camere de tratament. Toate informațiile sunt vizualizate în camera medicilor (camere Tele ICU). Aici sunt colectate toate informațiile vitale ale pacienților alături de imaginile lor în timp real. Toate acestea oferă și mai multă siguranță și acuratețe în îngrijirea copilului dumneavoastră.
          <br/>
          În intervalul 14.00-15.00 camerele pentru supraveghere vor fi focalizate pe copilul dumneavoastră și puteți avea imagini în timp real, cu el, în incubator. Pentru aceasta, veți primi un link individual, ce poate fi accesat de pe telefonul mobil personal sau de pe computer. Accesând acest link la ora 14.00 veți vedea, pentru 60 de minute,  în timp real ce face copilul dumneavoastră. <b>În cazul în care sunt necesare proceduri de urgență transmisia video se va întrerupe.</b>
          </h5>
        </GridItem>
      </GridContainer>

      <GridContainer justify="center" className={classes.newContent}>
        <GridItem xs={12} sm={12} md={12}>
          <Typography variant="title">Programul de gardă</Typography>
          <h5 className={classes.leftAligned}>
          <br/>
          Prezența părinților este binevenită în timpul programului de gardă,<b>până la ora 21.00</b>, pentru a discuta despre starea de sănătate a copilului. Garda este un timp propice pentru a afla informații despre starea de sănătate a copilului dumneavoastră.
          <br/>
          Dacă discuțiile au loc în camera medicilor, pentru a respecta intimitatea celorlalți aparținători, veți fi rugați să părăsiți încăperea în momentul în care se discută situația medicală a altor pacienți nou-născuți.          
          </h5>
        </GridItem>
      </GridContainer>

      <GridContainer justify="center" className={classes.newContent}>
      <GridItem xs={12} sm={12} md={12}>
        <Typography variant="title">Extragerea laptelui matern</Typography>
        <h5 className={classes.leftAligned}>  
        <br/>
        Punem la dispoziția mamelor pompe profesionale electrice, individuale pentru laptele matern și recipiente de depozitare. Extragerea laptelui matern se face în camera individuală a copilului. Laptele colectat trebuie transportat rapid în Banca de lapte și ținut în frigider. Dispunem de congelatoare speciale pentru laptele congelat și de frigidere dedicate depozitării laptelui  în Banca de lapte, aflată la demisolul clădirii. Laptele se administrează în recipiente de unica folosință, este etichetat cu un cod de bare și va fi administrat doar copilului dumneavoastră. Vi se va arăta unde să îl depozitați în frigider.
        </h5>
      </GridItem>
    </GridContainer>
    
    <GridContainer justify="center" className={classes.newContent}>
      <GridItem xs={12} sm={12} md={12}>
        <Typography variant="title">Cazarea mamelor</Typography>
        <h5 className={classes.leftAligned}>
        <br/>
        Dispunem de 8 locuri de cazare pentru mame în clădirea TINN. Ele sunt distribuite cu predilecție mamelor care nu sunt din București și celor care asigură lapte matern pentru copil. Putem caza mame, în limita locurilor disponibile, în secțiile cu care colaboram pentru rezolvarea afecțiunii  copilului dumneavoastră, respectiv chirurgie generala, neurochirurgie, cardiologie, ORL, pediatrie. Dacă internarea dumneavoastră se face în timpul zilei, asistenta șefă sau asistenta de salon vă vor ajuta în acest sens.
        </h5>
      </GridItem>
    </GridContainer>
    <GridContainer justify="center" className={classes.newContent}>
      <GridItem xs={12} sm={12} md={12}>
      <Typography variant="title">Parcare</Typography> 
       <h5 className={classes.leftAligned}>
        
        <br/>
        Spitalul oferă două parcări pentru dumneavoastră, lângă spital.
        </h5>
      </GridItem>
    </GridContainer>

    <GridContainer justify="center" className={classes.newContent}>
      <GridItem xs={12} sm={12} md={12}>
      <Typography variant="title">Contact</Typography>
        <h5 className={classes.leftAligned}>
        
        <br/>
        Telefon secție: +40(21)4603026, int. 412
        <br/>
        Telefon medici: +40(372)298506
        <br/>
        Fax: +40372110640
        <br/>
        E-mail: <a href="mailto:tinn@mscurie.ro">tinn@mscurie.ro</a>
        <br/>
        Adresa: Bulevardul Constantin Brâncoveanu 20, București 077120
        </h5>
      </GridItem>
    </GridContainer>

      </Paper>
    );
  }
}

export default withStyles(aboutStyle)(GeneralInfoSection);
