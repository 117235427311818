import axiosInstance from '../axiosInstance';
import Auth from "../modules/auth";

export const getAccess = async (value) => {
    try {
        axiosInstance.interceptors.response.use(response => {
            return response;
        }, error => {
            return error;
        });
        
        const request ={
            accessCode: value
        }
        
        var getAccess = await axiosInstance
            .post('/api/camera/access', request, {
                headers: {
                    Authorization: `bearer ${Auth.getToken()}`
                }
            });
        return getAccess.data;

    } catch (error) {
        console.log(error);
    }

}

export const getNow = async () => {
    try {
        axiosInstance.interceptors.response.use(response => {
            return response;
        }, error => {
            return error;
        });
     
        var getDate = await axiosInstance
            .get('/api/utils/now',  {
                headers: {
                    Authorization: `bearer ${Auth.getToken()}`
                }
            });
        return getDate.data;

    } catch (error) {
        console.log(error);
    }

}
export const getCameras = async () => {
    try {
        axiosInstance.interceptors.response.use(response => {
            return response;
        }, error => {
            return error;
        });
     
        var getCameras = await axiosInstance
            .get('/api/camera/',  {
                headers: {
                    Authorization: `bearer ${Auth.getToken()}`
                }
            });
        return getCameras.data;

    } catch (error) {
        console.log(error);
    }

}

export const addVisitor = async (visitor) => {
    try {
        axiosInstance.interceptors.response.use(response => {
            return response;
        }, error => {
            return error;
        });
     
        var addVisitor = await axiosInstance
            .post('/api/camera/addVisitor', visitor , {
                headers: {
                    Authorization: `bearer ${Auth.getToken()}`
                }
            });
        return addVisitor.data;

    } catch (error) {
        console.log(error);
    }

}

export const changeCameraState = async (state, id) => {
    try {
        axiosInstance.interceptors.response.use(response => {
            return response;
        }, error => {
            return error;
        });
     
        var changeState = await axiosInstance
            .put('/api/camera/setCameraState/' + id, state , {
                headers: {
                    Authorization: `bearer ${Auth.getToken()}`
                }
            });
        return changeState.data;

    } catch (error) {
        console.log(error);
    }

}

export const changeVisitorState = async (state, id) => {
    try {
        axiosInstance.interceptors.response.use(response => {
            return response;
        }, error => {
            return error;
        });
     
        var changeState = await axiosInstance
            .put('/api/camera/setVisitorState/' + id, state , {
                headers: {
                    Authorization: `bearer ${Auth.getToken()}`
                }
            });
        return changeState.data;

    } catch (error) {
        console.log(error);
    }

}

