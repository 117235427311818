import { container, title } from "../../material-kit-react";

const homePageStyle = {
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    marginTop: "24px",
    backgroundColor:"#F2F2F2",
    padding:0,
    width:"99%",
    paddingRight: "15px",
    paddingLeft: "15px",
    marginRight: "auto",
    marginLeft: "auto",
  },
 
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  details: {
    alignItems: 'center',
    flexDirection:'column'
  },
  column: {
    alignItems:'center',
    alignContent:'center',
    justifyContent:'center',
    height:'100%'
  },
  columnRow: {
    height:'100%',
    flex:1,
    flexBasis:"20%",
    flexDirection:'row',
    alignItems:"flex-end",
    justifyItems:"flex-end",
    alignContent:'flex-end',
  },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    minHeight: "32px",
    marginTop: "20px",
    fontWeight:"600",
    color: "#FFFFFF",
    textDecoration: "none"
  },
  camera: {
    padding:"10px",
    alignItems:"center",
    flex:1,
  },
  chipContent: {
    padding: "5px",
   // minHeight:"120px"
  },
  tabContainer: {
    minHeight:"250px",
    marginBottom:"20px",
    padding:"10px",
    margin:0,
    flexDirection:"row",
    alignItems:"stretch",
    ":parent" : {
      padding:"0!important",
      backgroundColor:"#ff0000"
    }
  },
  logo: {
    maxWidth: "50px",
    height: "50px",
  },
  root: {
    flexGrow: 1,
  },
  flex: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px auto 0"
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3"
  },
  mainRaised: {
    margin: "-30px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  }
};

export default homePageStyle;
