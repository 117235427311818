import React from "react";
import PropTypes from "prop-types";
import Auth from "../../modules/auth";

import axios from "./../../axiosInstance";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";
// @material-ui/icons

import People from "@material-ui/icons/People";

import Header from "../../components/Header/Header.jsx";
import Footer from "../../components/Footer/Footer.jsx";

import Grid from '@material-ui/core/Grid';
import Button from "../../components/CustomButtons/Button.jsx";

import Card from "../../components/Card/Card.jsx";
import CardBody from "../../components/Card/CardBody.jsx";
import CardHeader from "../../components/Card/CardHeader.jsx";
import CardFooter from "../../components/Card/CardFooter.jsx";
import CustomInput from "../../components/CustomInput/CustomInput.jsx";

import loginPageStyle from "../../assets/jss/material-kit-react/views/loginPage.jsx";

import image from "../../assets/img/all-hooked-up-1-1432712.jpg";

class LoginPage extends React.Component {
  /**
   * Class constructor.
   */
  constructor(props, context) {
    super(props, context);

    const storedMessage = localStorage.getItem("successMessage");
    let successMessage = "";

    if (storedMessage) {
      successMessage = storedMessage;
      localStorage.removeItem("successMessage");
    }

    // set the initial component state
    this.state = {
      errors: {},
      successMessage,
      cardAnimaton: "cardHidden",
      user: {
        username: "",
        password: ""
      }
    };

    this.processForm = this.processForm.bind(this);
    this.changeUser = this.changeUser.bind(this);
  }
  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }

  /**
   * Process the form.
   *
   * @param {object} event - the JavaScript event object
   */
  processForm(event) {
    // prevent default action. in this case, action is the form submission event
    event.preventDefault();

    if ((!this.state.user.username || this.state.user.username ==="") ||
    (!this.state.user.password || this.state.user.password ==="")) {
      this.setState({
        errors: { summary: "Utilizator si parola sunt obligatorii!" }
      })
      return;
    } 

    // create a string for an HTTP body message
    const username = this.state.user.username;
    const password = this.state.user.password;


    axios.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        if (error.response.status === 401) {
          this.setState({
            errors: { summary: "Utilizator sau parola gresite!" }
          });
        }
        return;
      }
    );
    axios
      .post("/auth/login", {
        username,
        password
      })
      .then(response => {
        if (response && response.status === 200) {
          // success

          // change the component-container state
          this.setState({
            errors: {}
          });
          // save the token
          Auth.authenticateUser(response.data.token, response.data.user);
          // update authenticated state
          this.props.toggleAuthenticateStatus();
          // redirect signed in user to dashboard
          this.props.history.push("/admin");
        } else if (response && response.status === 401) {
          const errors = { summary: "user or password incorrect" };

          this.setState({
            errors
          });
        } else if (response) {
          //failure

          //change the comppnent state
          const errors = response.errors ? response.errors : {};
          errors.summary = response.message;

          this.setState({
            errors
          });
        }
      });
  }

 

  /**
   * Change the user object.
   *
   * @param {object} event - the JavaScript event object
   */
  changeUser(event) {
    const field = event.target.name;
    const user = this.state.user;
    user[field] = event.target.value;

    this.setState({
      user
    });
  }

  /**
   * Render the component.
   */
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          color="transparent"
          fixed
          changeTabIndex={this.handleChangeIndex}
          {...rest}
        />
        <div className={classes.container}>
         <div style={{minHeight:"300px"}}>
          <Grid container
          direction="row"
  justify="center"
  alignItems="center">
          <Grid item xs={12} md={6} lg={4}>
                <Card className={classes[this.state.cardAnimaton]}>
                  <form className={classes.form} onSubmit={this.processForm}>
                    <CardHeader  className={classes.cardHeader}>
                      <Typography variant="headline" gutterBottom>
                        Autentificare
                      </Typography>
                    </CardHeader>
                    <CardBody>
                      <CustomInput
                        labelText="Utilizator..."
                        id="username"
                        formControlProps={{
                          fullWidth: true
                        }}
                        helperText={this.state.errors.username}
                        inputProps={{
                          type: "text",
                          name: "username",
                          required: true,
                          onChange: this.changeUser,
                          value: this.state.user.username,
                          endAdornment: (
                            <InputAdornment position="start">
                              <People className={classes.inputIconsColor} />
                            </InputAdornment>
                          )
                        }}
                      />
                      <CustomInput
                        labelText="Parola"
                        id="password"
                        formControlProps={{
                          fullWidth: true
                        }}
                        helperText={this.state.errors.username}
                        inputProps={{
                          type: "password",
                          name: "password",
                          required: true,
                          onChange: this.changeUser,
                          value: this.state.user.password,
                          endAdornment: (
                            <InputAdornment position="end">
                             
                            </InputAdornment>
                          )
                        }}
                      />
                    </CardBody>
                    <CardFooter className={classes.cardFooter}>
                      <Button color="secondary" type="submit">
                        Login
                      </Button>
                    </CardFooter>
                  </form>
                </Card>
                <div className={classes.errorMessages}>
                  {this.state.successMessage && (
                    <Typography variant="body2" style={{color:"#ff0000"}} gutterBottom align="center">
                      {this.state.successMessage}
                    </Typography>
                  )}
                  {this.state.errors.summary && (
                    <Typography variant="body1" style={{color:"#ff0000"}} gutterBottom align="center">
                      {this.state.errors.summary}
                    </Typography>
                  )}
                  
               
                 
                </div>
                </Grid>
                </Grid>
                </div>
                <Footer />
                </div>
                
              
              </div>
    );
  }
}

LoginPage.contextTypes = {
  router: PropTypes.object.isRequired
};

export default withStyles(loginPageStyle)(LoginPage);
