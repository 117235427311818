import { title, containerFluid, container } from "../../../material-kit-react.jsx";

const aboutStyle = {
  section: {
    textAlign: "center",
    backgroundColor: "#009AB0",
    minHeight: "400px",
    flex: 1,
    alignItems: "stretch",
    justifyItems: "center",
    display: "flex",
    padding:"20px",
    margin:0,
    ...container
  },
  label: {
    color: '#fff'
  },
  cameraTitle: {
    textAlign: "center",
    color:"#009AB0",
    position: "absolute",
    top: 0,
    left:0,
    width:"100%"
  },
  authenticate: {
    textAlign: "center",
    borderColor: "#fff",
    borderRadius: 20,
    borderWidth: 4,
    borderStyle: "solid",
    flex:1,
    paddingTop:"20px",
    color:"#fff"
  },
  videoContainer: {
    textAlign: "center",
    borderColor: "#fff",
    borderRadius: 20,
    borderWidth: 4,
    borderStyle: "solid",
    flex:1,
    padding:"5px",
    color:"#fff"
  },

  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
  description: {
    color: "#999"
  },
  titleDescription: {
    color: "#999",
    fontStyle: "italic",
    fontWeight: "600"
  },
  leftAligned: {
    textAlign: "left",
    color: "#999"
  },
  newContent: {
    marginTop: 50,
    marginBottom: 50
  },
  textField: {
    width: '50%',
    marginLeft: 'auto',
    marginRight: 'auto',            
    paddingBottom: 0,
    marginTop: 0,
    fontWeight: 500,
    color: 'white',
    textAlign: "center",
    ':after': {
      border: 'none'
    },
  },
  input: {
    color: 'white',
    borderColor: "#fff",
    borderRadius: 10,
    borderWidth: 2,
    borderStyle: "solid",
    fontSize: "16px",
    textAlign: "center"
  },


};

export default aboutStyle;
