import { createMuiTheme } from '@material-ui/core/styles';

const AppTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#009AB0',
      contrastDefaultColor: 'light'
    },
    secondary: {
      main: '#ED2553'
    },
    background: {
        default: '#000',
        contrastDefaultColor: 'light'
    },
  }
});

export default AppTheme;
