class Auth {
  /**
   * Authenticate a user. Save a token string in Local Storage
   *
   * @param {string} token
   */
  static authenticateUser(token, user) {
    localStorage.setItem('visit_token', token);
    localStorage.setItem('visit_userData', user.role);
    localStorage.setItem('visit_userId', user.user._id);
  }

  /**
   * Check if a user is authenticated - check if a token is saved in Local Storage
   *
   * @returns {boolean}
   */
  static isUserAuthenticated() {

    return localStorage.getItem('visit_token') !== null;
  }


  /**
   * Check if a user is authenticated - check if a token is saved in Local Storage
   *
   * @returns {boolean}
   */
  static isUserAdmin() {
    return localStorage.getItem('visit_userData') === "ADMIN";
  }



  /**
   * Deauthenticate a user. Remove a token from Local Storage.
   *
   */
  static deauthenticateUser() {
    localStorage.removeItem('visit_token');
    localStorage.removeItem('visit_userData');
    localStorage.removeItem('visit_userId');
  }

  /**
   * Get a token value.
   *
   * @returns {string}
   */

  static getToken() {
    return localStorage.getItem('visit_token');
  }
  /**
   * Get a token value.
   *
   * @returns {string}
   */

  static getUserId() {
    return localStorage.getItem('visit_userId');
  }
}

export default Auth;