import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Paper from '@material-ui/core/Paper';

// @material-ui/icons

// core components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";


import aboutStyle from "../../../assets/jss/material-kit-react/views/landingPageSections/aboutStyle.jsx";

class RulesSection extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Paper className={classes.section}>

        <GridContainer justify="center" className={classes.newContent}>
          <GridItem xs={12} sm={12} md={12}>
            <Typography variant="display1">Regulamentul de vizitare</Typography>
              <br/>
              <br/>
              <Typography variant="title">Programul de vizită al secției </Typography>
              <br/>
            <h5 className={classes.leftAligned}>
            Ca să reducem riscul de apariție al infecților în secția TINN și pentru a asigura intimitatea copilului dumneavoastră, pentru a păstra un climat de liniște și siguranță, citiți cu atenție regulamentul de vizită al nou-născuților.
            <br/>
            <br/>
            
            <br/>
            În calitate de <b>părinți, sunteți bineveniți până la ora 21.00, iar mama – oricând, chiar și în timpul nopții.</b>
            <br/>
            Puteți suna în secție la orice oră și să întrebați de starea de sănătate a nou-născutului dumneavoastră exceptând intervalul dintre orele <b>6.30 - 7.30</b> precum și <b>18.30 - 19.30</b> când se face schimbul de tură al asistentelor. Atunci când starea nou-născutului este gravă insistăm să fiți lângă copilul dumneavoastră, sau puteți suna de mai multe ori pe zi.
            <br/>
            Dacă alte persoane în afara părinților doresc să viziteze nou-născutul trebuie ca unul dintre părinți să le însoțească. În situația în care, ca părinți, nu puteți fi prezent în Secție două sau mai multe zile consecutiv, puteți desemna o persoană care să viziteze copilul în locul dumneavoastră. Vă rugăm să ne comunicați însă.
            <br/>
            Este permis accesul a <b>maximum 2 persoane in cameră. Reducem astfel riscul de infecții și respectăm intimitatea celorlalți pacienți. Recomandăm ca prezența vizitatorilor să nu interfere cu tratamente sau proceduri complicate ale copilului dumneavoastră.</b>
            </h5>
          </GridItem>
        </GridContainer>
        
        <GridContainer justify="center" className={classes.newContent}>
          <GridItem xs={12} sm={12} md={12}>  
          <Typography variant="title">Copiilor pînă la 16 ani le este interzis accesul în Secție.</Typography>
            <h5 className={classes.leftAligned}>
            
            <br/>
            Vizitatorilor le este permis accesul doar la copilul pentru care au venit, fără să îi deranjeze pe ceilalți. De asemenea, părinții sunt rugați <b>să-și viziteze propriul copil și să nu meargă în alte camere.</b> Astfel se reduce riscul de expunere la infecții în întreaga Secție.
            <br/>
            Vă rugăm să respectați intimitatea mamelor în timp ce alăptează sau extrag lapte.
            <br/>
            Dacă doriți să comunicați cu alți părinți, vă rugăm să vă întâlniți în afara spațiului de îngrijire al copiilor.
            </h5>
          </GridItem>
          
        </GridContainer>

        <GridContainer justify="center" className={classes.newContent}>
        <GridItem xs={12} sm={12} md={12}>  
        <Typography variant="title">Programul de "vizită" la distanță (camere video)</Typography>
          <h5 className={classes.leftAligned}>
         
          <br/>
          În cazul în care nu vă puteți vizita copilul, puteți fi „prezenți” în secție, în timp real și puteți afla informații despre copil din orice loc în care v-ați afla în România.
          <br/>
          Dispunem de un sistem de supraveghere performant în fiecare dintre cele 27 de camere de tratament. Toate informațiile sunt vizualizate în camera medicilor (camere Tele ICU). Aici sunt colectate toate informațiile vitale ale pacienților alături de imaginile lor în timp real. Toate acestea oferă și mai multă siguranță și acuratețe în îngrijirea copilului dumneavoastră.
          <br/>
          În intervalul <b>14.00-15.00</b> camera pentru supraveghere va fi focalizată pe copilul dumneavoastră și puteți avea imagini în timp real, cu el, în incubator. Pentru aceasta, veți primi un link individual, ce poate fi accesat de pe telefonul mobil personal sau de pe computer. Accesând acest link la ora 14.00 veți vedea, pentru 60 de minute,  în timp real ce face copilul dumneavoastră. <b>În cazul în care sunt necesare proceduri  de urgență transmisia video se va întrerupe.</b>
          </h5>
        </GridItem>
        
      </GridContainer>


      </Paper>
    );
  }
}

export default withStyles(aboutStyle)(RulesSection);
