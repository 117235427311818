import { title } from "../../../material-kit-react.jsx";

const aboutStyle = {
  section: {
    padding: "50px 24px",
    textAlign: "center"
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
  description: {
    color: "#999"
  },
  titleDescription: {
    color: "#999",
    fontStyle: "italic",
    fontWeight: "600"
  },
  leftAligned: {
    textAlign: "left",
    color: "#000"
  },
  newContent: {
    marginTop: 50,
    marginBottom: 50
  }

};

export default aboutStyle;
