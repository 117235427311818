import React, { Component } from "react";
//import injectTapEventPlugin from 'react-tap-event-plugin';
import { BrowserRouter, Route,  Redirect } from "react-router-dom";
import "./App.css";

import LoginPage from "./containers/LoginPage/LoginPage";
import HomePage from "./containers/HomePage/HomePage.jsx";
import AdminPage from "./containers/AdminPage/AdminPage.jsx";
import LogoutFunction from "./containers/LogoutFunction/LogoutFunction";

import { MuiThemeProvider } from "@material-ui/core/styles";
import AppTheme from "./AppTheme";

import Auth from "./modules/auth";


// remove tap delay, essential for MaterialUI to work properly
//injectTapEventPlugin();

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      (Auth.isUserAuthenticated()) ? (
        <Component {...props} {...rest} />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

const LoggedOutRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      Auth.isUserAuthenticated() ? (
        <Redirect
          to={{
            pathname: "/",
            state: { from: props.location }
          }}
        />
      ) : (
        <Component {...props} {...rest} />
      )
    }
  />
);

const PropsRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => <Component {...props} {...rest} />} />
);

class App extends Component {
  constructor() {
    super();
    this.state = {
      authenticated: false
    };
  }
  componentDidMount() {
    // check if user is logged in on refresh
    this.toggleAuthenticateStatus();
  }

  toggleAuthenticateStatus() {
    // check authenticated status and toggle state based on that
    this.setState({
      authenticated: Auth.isUserAuthenticated()
    });
  }

  render() {
    //<PrivateRoute path="/video" component={VideoPage} />
    var divStyle = {
      flex: 1,
      flexDirection: "column"
    };
    return (
      <MuiThemeProvider theme={AppTheme}>
        <BrowserRouter>
          <div style={divStyle}>
            <PropsRoute
              exact
              path="/"
              component={HomePage}
              toggleAuthenticateStatus={() => this.toggleAuthenticateStatus()}
            />
         {<PrivateRoute path="/admin" component={AdminPage} />}
         
            <LoggedOutRoute
              path="/login"
              component={LoginPage}
              toggleAuthenticateStatus={() => this.toggleAuthenticateStatus()}
            />
            <Route path="/logout" component={LogoutFunction} />
          </div>
        </BrowserRouter>
      </MuiThemeProvider>
    );
  }
}

export default App;
