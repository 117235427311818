import moment from 'moment-timezone'

moment.tz.setDefault("Romania/Bucharest");

export default function formatDate (fromDate,toDate, targetFormatMask, sourceFormatMask) {
  const countdownMillisecond = getDelta(fromDate,toDate, sourceFormatMask)
  const duration = moment.duration(countdownMillisecond)
  const finalTargetFormatMask = editTargetFormatMask(duration, targetFormatMask)
  const countdownString = moment(duration._data).format(finalTargetFormatMask)

  return [countdownMillisecond, countdownString]
}

export function getDelta (fromDate,toDate, sourceFormatMask) {
  if (!moment.isMoment(toDate)) {
    const convert = moment.isDate(toDate)
      ? moment(toDate)
      : moment(toDate, sourceFormatMask)
    return convert.diff(moment(fromDate).tz("Europe/Bucharest"))
  } else return toDate.diff(moment(fromDate).tz("Europe/Bucharest"))
}

function editTargetFormatMask (duration, targetFormatMask) {
  const changeDate = []
  if (duration.months() === 0) changeDate.push('M')
  if (duration.days() === 0) changeDate.push('D')
  return changeDate.reduce((acc, char) => {
    const charCount = acc.split('').filter((tfm) => tfm === char).length
    const charBefore = generateString(char, charCount)
    const charAfter = `[${generateString('0', charCount)}]`
    return acc.replace(charBefore, charAfter)
  }, targetFormatMask)
}

function generateString (character, count) {
  return Array(count).fill(character).join('')
}