import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Paper from '@material-ui/core/Paper';

// @material-ui/icons

// core components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";


import aboutStyle from "../../../assets/jss/material-kit-react/views/landingPageSections/aboutStyle.jsx";

class HowToSection extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Paper className={classes.section}>

        <GridContainer justify="center" className={classes.newContent}>
          <GridItem xs={12} sm={12} md={12}>
            <Typography variant="display1">Cum se foloseste</Typography>
              <br/>
              <br/>
              <Typography variant="title">Acces portal parinți</Typography>
              <br/>
            <h5 className={classes.leftAligned}>
            Ca să reducem riscul de apariție al infecților în secția TINN și pentru a asigura intimitatea copilului dumneavoastră, pentru a păstra un climat de liniște și siguranță, citiți cu atenție regulamentul de vizită al nou-născuților.
            
            <br/>
            <br/>
            
            <br/>
            În intervalul <b>14.00-15.00</b> camera pentru supraveghere va fi focalizată pe copilul dumneavoastră și puteți avea imagini în timp real, cu el, în incubator. Pentru aceasta, veți primi un link individual, ce poate fi accesat de pe telefonul mobil personal sau de pe computer. Accesând acest link la ora 14.00 veți vedea, pentru 60 de minute,  în timp real ce face copilul dumneavoastră. <b>În cazul în care sunt necesare proceduri  de urgență transmisia video se va întrerupe.</b>
            <br/>
            <br/>
            
            <ul>
            <li>Introduceți codul de access furnizat special pentru dumneavoastră </li>
            <br/>
            <li>Bifați <b>Sunt de acord cu regulamentul de vizitare prezentat</b></li>
            <br/>
            <li>Apăsați butonul access video si asteptati sa se incarce video live aferentă codului dvs de access.</li>
            </ul>
            </h5>
          </GridItem>
        </GridContainer>
        
      </Paper>
    );
  }
}

export default withStyles(aboutStyle)(HowToSection);
